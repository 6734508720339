import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
// import LocationPage from '../Pages/LocationPage/LocationPage';
// import Policy from '../Components/Policy/Policy';
import MainPage from '../Pages/mainPage/MainPage';
import ContactUs from '../Pages/contactUs/ContactUs';
import CloudKitchenFranchise from '../Pages/cloudKitchenFranchise/CloudKitchenFranchise';
import CartFranchise from '../Pages/cartFranchise/CartFranchise';
import PolicyPage from '../Pages/privacyPolicy/PolicyPage';

const Routers = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    
  return (
    <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/cloud-kitchen' element={<CloudKitchenFranchise />} />
        <Route path='cart-franchise' element={<CartFranchise />} />
        <Route path='/policy' element={<PolicyPage />} />
        {/* <Route path='/location' element={<LocationPage />} /> */}
    </Routes>
  )
}

export default Routers
