import React from 'react';
import "./style.scss";
import { Footer } from 'antd/es/layout/layout';
import footer_logo from "../../asset/images/footer-logo.svg";
import google_icon from "../../asset/images/google-icon.svg";
import insta_icon from "../../asset/images/insta-icon.svg";
import { Divider } from 'antd';
import rights_icon from "../../asset/images/rights_icon.svg";
import { useNavigate } from 'react-router-dom';

export const CustomFooter = () => {
    const navigate = useNavigate();
  return (
    <Footer className='footer-section'>
        <div className="footer-container">
            <div className="footer-logo pb-4">
                <img src={footer_logo} alt='img' />
            </div>
            <div className="footer-content">
                <div className="detail">It all started with a vision to empower small and medium-sized food entrepreneurs, enabling them to kickstart their food business with minimal investment. Additionally, it aims to offer business enthusiasts an opportunity to pursue entrepreneurship as a side hustle, providing them with an extra or secondary source of income.</div>
                <div className="list list-1">
                    <div className="list_title">Franchise Information</div>
                    <div className="list_item">How to Apply</div>
                    <div className="list_item">Franchise FAQs</div>
                    <div className="list_item">Franchise Benefits</div>
                    <div className="list_item cursor-pointer" onClick={() => navigate("/policy")}>Franchisee T&C</div>
                    <div className="list_item">Franchise Opportunities</div>
                </div>
                <div className="list list-2">
                    <div className="list_title">Menu</div>
                    <div className="list_item">Our Menu</div>
                    <div className="list_item">Specials</div>
                    <div className="list_item">Vegan & Gluten-Free Options</div>
                    <div className="list_item">Colorways</div>
                    <div className='list_item'></div>
                </div>
                <div className="list list-3">
                    <div className="list_title">Locations & Hours</div>
                    <div className="list_item">Find a Cart</div>
                    <div className="list_item">Operating Hours</div>
                    <div className="list_item">Event Schedule</div>
                    <div className="list_item">Wearables</div>
                    <div className="social_icons flex align-items-center">
                        <img src={google_icon} alt='img' />
                        <img src={insta_icon} alt='img' />
                    </div>
                </div>
            </div>
        </div>
        <Divider></Divider>
        <div className='rights-text'>
<img src={rights_icon} alt='c' />{" "}
TheMomosMafia 2024 | All rights reserved
        </div>

    </Footer>
  )
}

