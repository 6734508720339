import React from 'react'
import './style.scss'

const PolicyPage = () => {
  return (<div className="policy-section">
    <div className="policy-container max-width">
      {/* <ToolBar /> */}
      <div className="terms_section">
        <div className="terms-and-conditions">
          <div className="heading">Terms and Conditions</div>
          <div className="text">
          <span style={{fontWeight: "600"}} >MOMOIUM SPOT PVT. LTD</span>, trading under the name <span style={{fontWeight: "600"}}>The Momos Mafia</span>, is committed to delivering high-quality, hygienically prepared momos using only FSSAI-approved ingredients. We strive to establish a leading street momo brand by ensuring consistency, safety, and superior taste in every bite. Our mission is to uphold the highest standards of food safety and customer satisfaction, reflecting our dedication to excellence and authenticity in every aspect of our operations.
          </div>
          <section>
            <div className="title">Privacy Policy</div>
            <div className="text">
              {" "}
              At The Momos Mafia, we value your privacy and are committed to
              protecting your personal information. This Privacy Policy
              outlines how we collect, use, disclose, and safeguard your
              information when you visit our store page or use our services.
              By accessing or using our services, you agree to the terms of
              this Privacy Policy.
            </div>
            <ol>
              <li>
              Information We Collect
            <ul>
              <li>
                <div className="text">
                  Personal Information: We may collect personal information
                  such as your name, email address, phone number, delivery
                  address, and payment details when you place an order, sign
                  up for our newsletter, or contact us for support.
                </div>
              </li>
              <li>
                <div className="text">
                  Usage Data: We may automatically collect information about
                  how you interact with our website, including your IP
                  address, browser type, device information, pages visited,
                  and time spent on our site.
                </div>
              </li>
              <li>
                <div className="text">
                  Cookies and Tracking Technologies: We use cookies and
                  similar tracking technologies to enhance your browsing
                  experience and gather information about how you use our
                  website.
                </div>
              </li>
            </ul>
              </li>
              <li>
              How We Use Your Information
              <div className="text"> We use the information we collect to:</div>
            <ul>
              <li>
                <div className="text">Process and fulfill your orders.</div>
              </li>
              <li>
                <div className="text">
                  Communicate with you regarding your orders, inquiries, or
                  support requests.
                </div>
              </li>
              <li>
                <div className="text">
                  Improve our products, services, and website experience.
                </div>
              </li>
              <li>
                <div className="text">
                  Send you promotional offers, updates, and marketing
                  communications (you can opt out at any time).
                </div>
              </li>
              <li>
                <div className="text">
                  Analyze usage trends and monitor website performance.
                </div>
              </li>
              <li>
                <div className="text">
                  Ensure compliance with legal and regulatory requirements.
                </div>
              </li>
            </ul>
              </li>
              <li>
              Sharing Your Information
            <div className="text">
              We may share your information with third parties only in the
              following situations:
            </div>
            <ul>
              <li>
                <div className="text">
                  Service Providers: We may share your information with
                  third-party service providers who assist us in processing
                  payments, delivering orders, and providing customer support.
                </div>
              </li>
              <li>
                <div className="text">
                  Legal Compliance: We may disclose your information to comply
                  with applicable laws, regulations, legal processes, or
                  government requests.
                </div>
              </li>
              <li>
                <div className="text">
                  Business Transfers: In the event of a merger, acquisition,
                  or sale of assets, your information may be transferred as
                  part of the business assets.
                </div>
              </li>
            </ul>
              </li>
              <li>
              Data Security
            <div className="text">
              We implement reasonable security measures to protect your
              personal information from unauthorized access, disclosure, or
              misuse. However, no data transmission over the internet or
              storage system can be guaranteed 100% secure. We encourage you
              to take precautions to protect your personal information.
            </div>
              </li>
              <li>
              Your Choices
            <ul>
              <li>
                <div className="text">
                  Opt-Out: You may opt-out of receiving marketing
                  communications from us by following the unsubscribe link in
                  any email or by contacting us directly.
                </div>
              </li>
              <li>
                <div className="text">
                  Cookies: You can adjust your browser settings to refuse
                  cookies or alert you when cookies are being sent.
                </div>
              </li>
            </ul>
              </li>
              <li>
              Third-Party Websites
            <div className="text">
              Our website may contain links to third-party websites. We are
              not responsible for the privacy practices or content of these
              websites. We encourage you to read the privacy policies of any
              third-party sites you visit.
            </div>
              </li>
              <li>
              Changes to This Privacy Policy
            <div className="text">
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page with an updated effective date. We
              encourage you to review this policy periodically to stay
              informed about how we are protecting your information.
            </div>
              </li>
            </ol>
           
       
            
        
          
         
            
         
          </section>

          <section>
            <div className="title">Acceptance of Terms</div>
            <div className="text">
              These terms outline your legal rights and responsibilities when
              accessing and using The Momos Mafia’s online platform
              ("Platform"). By using the Platform, you agree to be bound by
              these terms, constituting an agreement between you and The Momos
              Mafia. If you do not accept the terms or are unable to be bound
              by them, please do not use the Platform.
            </div>
          </section>

          <section>
            <div className="title">Services Overview</div>
            <div className="text">
              The Momos Mafia Platform allows franchise partners to purchase
              momos, chutneys, spring rolls, disposables, and other items
              essential for running a momo business. We take full
              responsibility for the quality, quantity, and pricing of the
              products offered on the Platform. Our commitment ensures that
              you receive top-quality products at competitive prices. The
              Momos Mafia primarily acts as a facilitator between franchise
              partners and third-party suppliers, ensuring all transactions
              meet our high standards.
            </div>
          </section>

          <section>
            <div className="title">Eligibility</div>
            <div className="text">
              The Platform is intended for franchise partners only. You must
              be legally competent to contract under the Indian Contract Act,
              1872. Minors are not eligible to use the Platform. Business
              users, such as retailers or wholesalers, are also prohibited
              from using the Platform.
            </div>
          </section>

          <section>
            <div className="title">Account & Registration Obligations</div>
            <div className="text">
              Franchise partners must register and log in to place orders on
              the Platform. It is your responsibility to keep your account
              information up to date. By registering, you agree to receive
              communications from The Momos Mafia and its affiliates. You may
              opt-out or change notification preferences through your account
              settings.
            </div>
          </section>

          <section>
            <div className="title">Limited License & Access</div>
            <div className="text">
              The Momos Mafia grants you a limited, non-exclusive,
              non-transferable, and revocable license to access the Platform
              for personal use only. Any resale, modification, or commercial
              use of the Platform or its contents is prohibited without prior
              written consent. Unauthorized access or use will result in
              termination of the license.
            </div>
          </section>

          <section>
            <div className="title">Prohibited Conduct</div>
            <div className="text">
              You agree not to engage in any prohibited conduct on the
              Platform, including but not limited to, uploading harmful,
              offensive, or illegal content, attempting unauthorized access,
              or engaging in activities that violate intellectual property
              rights or applicable laws.
            </div>
          </section>

          <section>
            <div className="title">Liability</div>
            <div className="text">
              The Momos Mafia is not liable for any damages or losses
              resulting from the use of the Platform or the products/services
              offered by third-party suppliers. All liabilities arising from
              the violation of these terms are your sole responsibility.
            </div>
          </section>

          <section>
            <div className="title">Advertising</div>
            <div className="text">
              Some sections of the Momos Mafia website may contain advertising
              or promotional content from third parties. Responsibility for
              ensuring that such content complies with all applicable laws
              lies solely with the party providing the material. Your
              interactions with advertisers on the platform, including payment
              and delivery of goods or services, are solely between you and
              the advertiser. Momos Mafia is not liable for any inaccuracies,
              errors, or damages resulting from these interactions.
            </div>
            <div className="text">
              For charitable campaigns displayed on our platform, Momos Mafia
              is not involved in the collection or utilization of funds. The
              information provided is for informational purposes only, and
              users should independently verify details before taking action.
              Any participation in marketing or promotional campaigns on the
              Momos Mafia platform is at your own risk, governed by the terms
              of the advertiser or sponsor, with no liability on Momos Mafia's
              part.
            </div>
          </section>

          <section>
            <div className="title">Disclaimers</div>
            <div className="text">
              You acknowledge that your use of the Momos Mafia platform and
              its services is at your own risk. We strive to display products
              accurately, but actual items may vary in color, size, shape, and
              appearance. Momos Mafia does not endorse or guarantee the
              products available on the platform, nor do we hold any ownership
              or control over the products. We are not responsible for the
              quality, availability, or any delays in delivery of third-party
              products.
            </div>
          </section>

          <section>
            <div className="title">Delivery Partners</div>
            <div className="text">
              Momos Mafia facilitates order delivery through independent
              contractors. These delivery partners are not employees or agents
              of Momos Mafia but operate as independent service providers.
              There is no principal-agent relationship between Momos Mafia and
              the delivery partners, and we are not liable for their actions
              or omissions. Users are expected to treat delivery partners with
              respect and courtesy. Momos Mafia reserves the right to limit or
              revoke access to the platform if users engage in inappropriate
              behavior towards delivery partners.
            </div>
          </section>

          <section>
            <div className="title">Reviews, Feedback, Submissions</div>
            <div className="text">
              All comments, reviews, and feedback submitted on the Momos Mafia
              platform become the property of Momos Mafia. By submitting
              comments, you grant us a perpetual, worldwide, royalty-free
              license to use, reproduce, and distribute your submissions for
              any purpose, including marketing. Momos Mafia is not obligated
              to maintain confidentiality, compensate for, or respond to any
              comments. Users are responsible for ensuring that their comments
              do not violate any third-party rights or contain unlawful
              content. Momos Mafia reserves the right to monitor, edit, or
              remove any comments.
            </div>
          </section>

          <section>
            <div className="title">Intellectual Property</div>
            <div className="text">
              Momos Mafia and its partners reserve all intellectual property
              rights for the content on the platform. Access to the platform
              does not grant any license or rights to use this content without
              prior written consent. Unauthorized use, reproduction, or
              distribution of the content is prohibited. Trademarks, logos,
              and service marks on the platform are the property of Momos
              Mafia and its partners. References to third-party sites are
              provided for convenience, and Momos Mafia is not responsible for
              their content or accuracy.
            </div>
          </section>

          <section>
            <div className="title">Information Disclosure</div>
            <div className="text">
              Momos Mafia takes necessary precautions to protect your
              information. Our servers are accessible only to authorized
              personnel, and your data is shared on a need-to-know basis.
              However, as internet transmissions cannot be fully secured, we
              are not liable for any information disclosure due to
              transmission errors or unauthorized third-party actions.
            </div>
          </section>

          <section>
            <div className="title">Call Recording</div>
            <div className="text">
              All calls between you and Momos Mafia (including delivery
              partners) may be recorded for quality purposes. By using our
              services, you consent to this recording and its use by
              authorized personnel. Additionally, call recordings and related
              data may be shared with affiliates or government authorities if
              legally required.
            </div>
          </section>

          <section>
            <div className="title">Misrepresentation</div>
            <div className="text">
              Momos Mafia is not responsible for any unauthorized
              communications, fraud, or misrepresentations by third parties.
              Users should be cautious of imposters and unauthorized contact
              claiming to represent Momos Mafia. For official communications,
              only use the channels specified in these terms.
            </div>
          </section>

          <section>
            <div className="title">Refund & Cancellation</div>
            <div className="text">
              You are responsible for providing accurate delivery details and
              making payments for orders placed through your account. Momos
              Mafia is not liable for issues caused by incorrect information
              or delays beyond our control. Products delivered are
              non-returnable, except under specific conditions like defects or
              damage at the time of delivery.
            </div>
            <div className="text">
              Refunds for permitted returns or cancellations will be processed
              within 7 working days. Refunds for online payments will be
              processed in the same manner as received; for cash on delivery,
              refunds will be issued as promotional codes.
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PolicyPage;